import React, {useState} from "react";

import '../css/keckzoneBottomBar.css';

export const KeckzoneBottomBar = ({}) => {

    const [isMenuOpen, setMenuOpen] = useState(false);

    function handleMenuClick() {
        setMenuOpen(!isMenuOpen);
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
            /* you can also use 'auto' behaviour
               in place of 'smooth' */
        });
    }

    return(

    <div>
        <div className={"keckzonebottombar"} ><br/>
            Longpap.es{'\u00A0'}is{'\u00A0'}a{'\u00A0'}service{'\u00A0'}of{'\u00A0'}the{'\u00A0'}
            <span className={"keckzonetext"} onClick={() => handleMenuClick()}>Keckzone{'\u00A0'}Multiverse</span>.
            ©{new Date().getFullYear()}{'\u00A0'}All{'\u00A0'}rights{'\u00A0'}reserved.
        </div>
        <br/>
        <LinkMenu isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
    </div>
    );
}

export const LinkMenu = ({isMenuOpen, setMenuOpen}) => {
    const styles={
        container: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: isMenuOpen? '100%': 0,
            width: '100vw',
            display: 'flex',
            flexDirection: 'column',
            background: 'black',
            opacity: 0.95,
            color: '#fafafa',
            transition: 'height 0.3s ease',
            zIndex: 2,
        }
    }

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    }

    return(
        <div style={styles.container}>
            {
                isMenuOpen?
                    <div>
                        <div onClick={() => setMenuOpen(false)} on ><a className={"close"}></a></div>
                        <div className={"innertext"}>Welcome to the Keckzone</div>
                        <div className={"social-media"}>
                            <a onClick={() => openInNewTab("https://rateth.at")}><img src={"https://rateth.at/public/icon-512x512.png"}/>
                            <span>rateTH.AT</span></a>
                            <a onClick={() => setMenuOpen(false)}><img src={"https://longpap.es/logo512.png"}/>
                            <span>longpap.es</span></a>
                            <a onClick={() => openInNewTab("https://www.youtube.com/channel/UCc4Q2pu7tGJwSSvj0mch11g")}><img src={"https://longpap.es/youtube-white.png"}/>
                            <span>/Kecksohn</span></a>
                            <a onClick={() => openInNewTab("https://twitter.com/Kecksohn")}><img src={"https://longpap.es/twitter.png"}/>
                            <span>/Kecksohn</span></a>
                        </div>
                        <div className={"bottomText"}>All code by Kecksohn and definitely not by some guy bugfixing all of my stuff for free. Check out his <a onClick={() => openInNewTab("https://soundcloud.com/fabfab2")}>soundcloud</a> and play his game PettingSimulator2001 <a onClick={() => openInNewTab("https://fab2.at/")}>here</a></div>
                    </div>:null
            }
        </div>
    )
}
