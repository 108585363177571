import React, {useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';

import styled from 'styled-components';



var prefetchedImages = [];


export const InfinitePapes = ({papesList, setFullscreen, expandAll, setExpandAll}) => {

    // Get 3 new Papes everytime the lazy-load is triggered
    const interval = 3;

    const [currentPapes, setCurrentPapes] = useState ([...papesList.slice (0, interval)]);
    const [nextPapeIndex, setIndex] = useState (interval + interval);


    const loadMore = () => {
        setIndex (nextPapeIndex + interval);
        setCurrentPapes ([...papesList.slice (0, nextPapeIndex)]);
    };

    return (
        <div>
            <ul className="ins-imgs">

                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMore}
                    hasMore={currentPapes.length < papesList.length && papesList.length !== 0}
                    loader={<div className="loader" key={0}>Loading ...</div>}
                >

                {currentPapes.length > 0 &&
                currentPapes.map (pape => <Wallpaper  key={pape.filename}
                                src={`https://longpap.es/${pape.src}`}
                                altsrc={`https://longpap.es/${pape.altsrc}`}
                                filename={pape.filename}
                                width={pape.width}
                                height={pape.height}
                                aspectRatio={pape.aspectRatio}
                                expandAll={expandAll}
                                setExpandAll={setExpandAll}
                                setFullscreen={setFullscreen}/>)
                }

                </InfiniteScroll>

            </ul>
        </div>
    );

};

const Wallpaper = ({src, altsrc, filename, width, height, aspectRatio, expandAll, setExpandAll, setFullscreen}) => {

    const [zoom, setZoom] = useState(false);
    const [zoomedOnce, setZoomedOnce] = useState(false);
    const [hidden, setHidden] = useState(false);

    const [overrideExpandAll, setOverrideExpandAll] = useState(false);


    if (!expandAll && overrideExpandAll) {
        setOverrideExpandAll(false);
    }

    function expandPape() {
        if(!zoomedOnce) {
            const img = new Image();
            img.src = altsrc;
            prefetchedImages.push(img);
            img.src = src;
            prefetchedImages.push(img);
            setZoomedOnce(true);
        }
        if (expandAll && !overrideExpandAll) {
            setOverrideExpandAll(true);
            setZoom(false);
        }
        else {
            setZoom(!zoom);
        }
    }

    function click(event) {

        //we need these zoom values for the animation
        if( event.button === 0 ) {
            expandPape()
        }

        if( event.button === 1 ) {
            if (hidden) {
                var win = window.open(altsrc, '_blank');
                win.focus();
            }
            else {
                var win = window.open(src, '_blank');
                win.focus();
            }
        }
    }

    let newheight = Math.min(window.innerWidth, 900) * aspectRatio[1] / aspectRatio[0];
    //set newwidth also first

    //then possible fix for height of phone wallpapers on desktop
    if (window.innerWidth > 900 && aspectRatio[0] < aspectRatio[1]) {
        //calculate new width according to height

        //also calculate newheight from that

    }

    return (
        <li className={'ins-imgs-li'}>
            <div className={zoom || (expandAll && !overrideExpandAll) ? "ins-imgs-img-zoom" : "ins-imgs-img"}
                 style={!zoom && !zoomedOnce && !setExpandAll ? {height: newheight, overflow: 'hidden'} : {height: "auto"}}>
                <img src={hidden ? altsrc : src} alt={filename} title={filename}
                     onMouseDown={event => click(event)}/>
            </div>

            <div className={zoom || (expandAll && !overrideExpandAll) ? "ins-imgs-label-zoom" : 'ins-imgs-label'}>{`${filename}, Resolution: ${width}x${height} (${aspectRatio[0]+":"+aspectRatio[1]})`}</div>

            {zoomedOnce && altsrc !== "https://longpap.es/" &&
                <div className={zoom  || (expandAll && !overrideExpandAll) ? "ins-imgs-label-zoom" : 'ins-imgs-label'}>
                <span>There exists a
                    {!hidden && <span onClick={() => setHidden(true)} style={{cursor: "default"}}> hidden </span>}
                    {hidden && <span onClick={() => setHidden(false)} style={{cursor: "default"}}> regular </span>}
                    version of this pape as well</span>
                </div>}

        </li>
    )
};
