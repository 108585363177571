import React, {useRef, useState} from "react";
import {LatestPapesButton, ResolutionButton, ChooseResolutionButton} from "./MenuButtons";

import '../css/blackButton.css';

export const StartScreen = ({resolutions, filters, setFirstInput, setFilters, setFiltersChanged,
                                orientation, setOrientation, setShowExactButton, setLatestPapesClicked}) => {

    return (
        <>
            {<div>
                <ResolutionButton  key={"DetectResolution"}
                tag={"Detect Screen Resolution"}
                minWidth={Math.round(window.screen.width * (window.devicePixelRatio || 1))}
                minHeight={Math.round(window.screen.height * (window.devicePixelRatio || 1))}
                maxWidth={-1}
                maxHeight={-1}
                filters={filters}
                setFilters={setFilters}
                setFiltersChanged={setFiltersChanged}
                setOrientation={setOrientation}
                setShowExactButton={setShowExactButton}
                setFirstInput={setFirstInput}
                buttoncssclass={"startbutton"}
                //className={"bigmenu"}
            /></div>}

            {<div>
                    <ChooseResolutionButton resolutions={resolutions} tag={"Choose Specific Size"}
                                            filters={filters} setFilters={setFilters} setFiltersChanged={setFiltersChanged}
                                            orientation={orientation} setOrientation={setOrientation} setShowExactButton={setShowExactButton}
                                            setFirstInput={setFirstInput} buttoncssclass={"startbutton"} />
            </div>}

            {<div>
                <ResolutionButton  key={"AllSizes"}
                              tag={"Show All Papes"}
                              minWidth={-1}
                              minHeight={-1}
                              maxWidth={-1}
                              maxHeight={-1}
                              filters={filters}
                              setFilters={setFilters}
                              setFiltersChanged={setFiltersChanged}
                               orientation={orientation}
                              setOrientation={setOrientation}
                              setShowExactButton={setShowExactButton}
                              setFirstInput={setFirstInput}
                               buttoncssclass={"startbutton"}/>
            </div>}

            {<div>
                <LatestPapesButton key={"LatestPapes"}
                                   tag={"Show Latest Papes"}
                                   setFiltersChanged={setFiltersChanged}
                                   setOrientation={setOrientation}
                                   setShowExactButton={setShowExactButton}
                                   setFirstInput={setFirstInput}
                                   setLatestPapesClicked={setLatestPapesClicked}
                                   buttoncssclass={"startbutton"}/>
            </div>}

        </>)

}
