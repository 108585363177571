import { useParams } from 'react-router-dom';
import {Main} from "./Main";

export const ResolutionParser = () => {
    const { resolution_string, additional_specifier } = useParams();

    let [minWidth, minHeight] = resolution_string.split("x");
    minWidth = parseInt(minWidth);
    minHeight = parseInt(minHeight);
    let orientation = "equal"
    let exact = false;

    if (additional_specifier) {
        if (additional_specifier === "exact") {
            exact = true;
        }
        else if (additional_specifier === "desktop") {
            orientation = "width";
        }
        else if (additional_specifier === "mobile") {
            orientation = "height";
        }
    }

    return (
        <Main
            firstInputParam={true}
            minWidthParam={minWidth}
            minHeightParam={minHeight}
            orientationParam={orientation}
            exactParam={exact}
            showExactButtonParam={!exact}
        />
    );
}