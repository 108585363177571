import React, { useContext, createContext, useState, useEffect } from 'react';
import { Main } from "./Main";
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';

import './css/App.css';
import {ResolutionParser} from "./ResolutionParser";

export const AppContext = React.createContext(null);

const PapesLoader = ({children}) => {
    const [fullPapesList, setFullPapesList] = React.useState(null);
    const [fullPapesListLoad, setFullPapesListLoaded] = React.useState(false);

    React.useEffect(() => {
        // Fetch your data here and save it in state
        // Just a placeholder, replace with your actual data fetching mechanism
        fetch('https://longpap.es/read.php')
            .then(resp => resp.json())
            .then(resp => {

                resp.records.map(record => {
                    const name = record.name;
                    try{
                        record.name = record.name;
                    }
                    catch(e) {
                        record.name = name;
                        console.log('error parsing name: ', record.name, e);
                    }

                });

                setFullPapesList(resp.records);
                setFullPapesListLoaded(true);
            })
            .catch(err => console.log('problem fetching: ', err))
    }, []);

    return <AppContext.Provider value={{fullPapesList, fullPapesListLoad}}>{children}</AppContext.Provider>;
}

function App() {
    return (
        <PapesLoader>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Main />}/>
                    <Route path="/:dynamicPage" element={<Main />}/>
                    <Route path={"/latest"} element={<Main firstInputParam={true} latestPapesClickParam={true} />}/>
                    <Route path={"/all"} element={<Main firstInputParam={true} minHeightParam={-1} minWidthParam={-1} />}/>
                    <Route path={"/desktop"} element={<Main firstInputParam={true} orientationParam={"width"} />}/>
                    <Route path={"/mobile"} element={<Main firstInputParam={true} orientationParam={"height"} />}/>
                    <Route path={"/res/:resolution_string/:additional_specifier?"} element={<ResolutionParser />}/>
                </Routes>
            </BrowserRouter>
        </PapesLoader>
    );
}

export default App;
