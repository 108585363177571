import React, {useState, useEffect, useContext} from 'react';
import { AppContext } from './App';
import { useLocation } from 'react-router-dom';
import { parse_url_to_filter} from "./ResolutionParser";

import {StartScreen} from "./menus/StartScreen";
import {FilterBar} from "./menus/FilterBar";
import {InfinitePapes} from "./InfinitePapes";
import {LatestGallery} from "./special/LatestGallery";
import {DizasterEasteregg} from "./special/DizasterEasteregg";
import {KeckzoneBottomBar} from "./special/KeckzoneBottomBar";

import './css/container.css';
import './css/startText.css';
import './css/blackButton.css';
import './css/papes.css';
import {HeaderBar} from "./menus/HeaderBar";
import {ExpandButton} from "./menus/ExpandButton";

export const Main = ({
                         firstInputParam = false,
                         minWidthParam = -1,
                         minHeightParam = -1,
                         maxWidthParam = -1,
                         maxHeightParam = -1,
                         aspectRatioParam = "",
                         orientationParam = "equal",
                         latestPapesClickParam = false,
                         exactParam = false,
                         showExactButtonParam = false
                     }) => {

    const {fullPapesList, fullPapesListLoad} = useContext(AppContext);

    const [firstInput, setFirstInput] = useState(firstInputParam);
    const [listFetched, setListFetched] = useState(false);

    //const [fullPapesList, setFullPapesList] = useState([]);
    const [currentPapes, setCurrentPapes] = useState([]);
    const [latestPapes, setLatestPapes] = useState([]);
    const [galleryPapes, setGalleryPapes] = useState([]);

    const [latestActive, setLatestActive] = useState(false);
    const [latestPapesClicked, setLatestPapesClicked] = useState(latestPapesClickParam);

    const [filtersChanged, setFiltersChanged] = useState(false);
    const [filters, setFilters] = useState({
        minWidth: minWidthParam,
        minHeight: minHeightParam,
        maxWidth: maxWidthParam,
        maxHeight: maxHeightParam,
    });
    const [aspectRatio, setAspectRatio] = useState(aspectRatioParam);
    const [orientation, setOrientation] = useState(orientationParam);
    const [exact, setExact] = useState(exactParam);
    const [showExactButton, setShowExactButton] = useState(showExactButtonParam);

    const RESOLUTIONS = [
        {name: '4K', width: 3840, height: 2160},
        {name: '1440p', width: 2560, height: 1440},
        {name: '1080p', width: 1920, height: 1080},
        {name: '5K', width: 5120, height: 2880},
        {name: '8K', width: 7680, height: 4320}
    ];

    const [expandAll, setExpandAll] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);

    const location = useLocation();
    useEffect(() => {
        const reloadComponent = () => {
            // Logic for reloading the component or fetching new data
            console.log('Location changed:', location.pathname);
            // Your custom logic here, like fetching data based on the new route
        };

        reloadComponent();

        // The useEffect cleanup function
        return () => {
            // Any cleanup logic if needed
        };
    }, [location]);

    useEffect(() => {
        setFirstInput(firstInputParam);
        setFilters({
            ...filters,
            minWidth: minWidthParam,
            minHeight: minHeightParam,
            maxWidth: maxWidthParam,
            maxHeight: maxHeightParam,
        });
        setAspectRatio(aspectRatioParam)
        setOrientation(orientationParam);
        setLatestPapesClicked(latestPapesClickParam);
        setExact(exactParam);
        setShowExactButton(showExactButtonParam);
        setFiltersChanged(true);
    }, [firstInputParam,
        minWidthParam,
        minHeightParam,
        maxWidthParam,
        maxHeightParam,
        aspectRatioParam,
        orientationParam,
        latestPapesClickParam,
        exactParam,
        showExactButtonParam]);

    useEffect(() => {
        if (!listFetched && fullPapesListLoad) {
            setListFetched(true);

            let list_lastModified = sortByDate(fullPapesList.slice(0));
            setLatestPapes(list_lastModified);

            let list_shuffled = shuffle(fullPapesList.slice(0));
            setCurrentPapes(list_shuffled);

            let list_widescreen = list_shuffled.slice(0);
            list_widescreen = list_widescreen.filter(pape =>
                pape.width > pape.height
            );
            setGalleryPapes(list_widescreen);

            if (firstInputParam) {
                setFiltersChanged(true);
            }
        }

        if (filtersChanged && listFetched) {
            changeFilters();
        }

    }, [listFetched, fullPapesListLoad, filtersChanged, fullPapesList, firstInputParam, changeFilters]);

    function changeFilters() {

        let helperList;

        if (latestPapesClicked) {
            helperList = latestPapes.slice(0);
            setLatestActive(true);
            setLatestPapesClicked(false);
        }

        else {
            setLatestActive(false);
            helperList = fullPapesList.slice(0);

            if (filters.minWidth === -1 && filters.minHeight === -1 && orientation === "equal") {
                setExact(false);
            }
            else {

                if (filters.minWidth > 0 && filters.minHeight > 0) {
                    setAspectRatio(getAspectRatio(filters.minWidth, filters.minHeight));
                }

                if (exact) {
                helperList = helperList.filter(pape =>
                    pape.width === filters.minWidth && pape.height === filters.minHeight);
                }
                else {
                    helperList = helperList.filter(pape =>
                    pape.width >= filters.minWidth &&
                    pape.height >= filters.minHeight &&
                    (filters.maxWidth < 0 || pape.width <= filters.maxWidth) &&
                    (filters.maxHeight < 0 || pape.height <= filters.maxHeight) &&
                    (orientation === "equal" ?
                        true : orientation === "width" ?
                            pape.width > pape.height : pape.height > pape.width)
                    );
                }
            }

            shuffle(helperList);
        }

        setCurrentPapes(helperList);
        setFiltersChanged(false);
    }




    function shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }

    function sortByDate(array) {
        array.sort((a, b) => b.lastModified - a.lastModified);
        return array;
    }

    function getAspectRatio(a, b) {

        function gcd(a,b) {
            if (b === 0) {
                return a;
            }
            return gcd (b, a%b);
        }

        let divisor = gcd(a,b);
        if (divisor !== 0) return [(a/divisor), (b/divisor)];
        return [a, b];
    }



    return (
        <div>

            {!firstInput && <div><br />
                <div className={"menu-text"}>
                How big do you want your next wallpaper to be?
                </div><br /></div>}

            {!firstInput &&
                <div className="all-container">
                    <div className="small-container">
                        <StartScreen resolutions={RESOLUTIONS} setFirstInput={setFirstInput}
                                    filters={filters} setFilters={setFilters} setFiltersChanged={setFiltersChanged}
                                    orientation={orientation} setOrientation={setOrientation} setShowExactButton={setShowExactButton}
                                    setLatestPapesClicked={setLatestPapesClicked} />
                    </div>
                    <div className="big-div">
                        <LatestGallery listFetched={listFetched} galleryPapes={galleryPapes} />
                    </div>
                </div>}





            {firstInput && <HeaderBar setFirstInput={setFirstInput} />}

            {firstInput && <FilterBar resolutions={RESOLUTIONS}
                        filters={filters} setFilters={setFilters} setFiltersChanged={setFiltersChanged}
                        setLatestPapesClicked={setLatestPapesClicked}
                        orientation={orientation} setOrientation={setOrientation}
                        exact={exact} setExact={setExact} showExactButton={showExactButton} setShowExactButton={setShowExactButton}/> }



            {firstInput && !latestActive && <div className={"foundpapers"}> <br/>Found {currentPapes.length}
                {orientation==="equal" ? " " : orientation==="width" ? " Desktop-" : " Mobile-"}Pape{currentPapes.length===1 ? "":"s"}
                {exact ? ` with an exact resolution of ${filters.minWidth}x${filters.minHeight} ($${aspectRatio[0]+":"+aspectRatio[1]})` :
                    `${filters.minWidth > 0 
                        ? `${filters.minHeight > 0 ? 
                            ` with a minimum resolution of ${filters.minWidth}x${filters.minHeight} (${aspectRatio[0]+":"+aspectRatio[1]})` : 
                            ` with a minimum width of ${filters.minWidth}`
                            }` 
                        : `${filters.minHeight > 0 ? `
                            with a minimum height of ${filters.minHeight}` :
                            ``
                            }` 
                    }`
                }
             </div> }
            {firstInput && latestActive && <div className={"foundpapers"}> <br/>Showing Latest Papes</div>}

            {firstInput && <ExpandButton expandAll={expandAll} setExpandAll={setExpandAll} />}

            {firstInput && listFetched &&
                <InfinitePapes key={filtersChanged} papesList={currentPapes}
                               expandAll={expandAll} setExpandAll={setExpandAll} setFullscreen={setFullscreen}/>}



            {firstInput && listFetched && currentPapes.length === 0 && !exact && <DizasterEasteregg />}



            {<KeckzoneBottomBar/>}

        </div>
    );

}
