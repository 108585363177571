import React, { useState } from 'react';
import {ChooseResolutionButton, LatestPapesButton, ResolutionButton} from "./MenuButtons";
import {useNavigate} from "react-router-dom";


export const FilterBar = ({resolutions, filters, setFilters, setFiltersChanged, setLatestPapesClicked,
                              orientation, setOrientation,
                              exact, setExact, showExactButton, setShowExactButton}) => {

    const navigate = useNavigate();

    function changeOrientation(orient) {
        setOrientation(orient);
        setShowExactButton(true);
        setFiltersChanged(true);
        if (filters.minWidth === -1 && filters.minHeight === -1) {
            if (orient === "equal") {
                navigate("/");
            }
            else if (orient === "width") {
                navigate("/desktop");
            }
            else if (orient === "height") {
                navigate("/mobile");
            }
        }
        else {
            if (orient === "equal") {
                navigate("/res/" + filters.minWidth + "x" + filters.minHeight);
            }
            else if (orient === "width") {
                navigate("/res/" + filters.minWidth + "x" + filters.minHeight + "/desktop");
            }
            else if (orient === "height") {
                navigate("/res/" + filters.minWidth + "x" + filters.minHeight + "/mobile");
            }
        }
    }

    function changeExact() {
        setExact(!exact);
        setFiltersChanged(true);
        if (exact) {
            navigate("/res/" + filters.minWidth + "x" + filters.minHeight);
        }
        else {
            navigate("/res/" + filters.minWidth + "x" + filters.minHeight + "/exact");
        }
    }

    return (
        <div className={"button-container"}>
            {<ChooseResolutionButton resolutions={resolutions} tag={"Change Resolution"}
                                     filters={filters} setFilters={setFilters} setFiltersChanged={setFiltersChanged}
                                     setShowExactButton={setShowExactButton} orientation={orientation} setOrientation={setOrientation}
            buttoncssclass={"filterbutton"}/>}

            {showExactButton ?
                exact ? <button type="button" className={"filterbutton"} onClick={() => changeExact()}>Show Bigger Papes
                        Too</button>
                    : <button type="button" className={"filterbutton"} onClick={() => changeExact()}>Only Show Exact
                        Size</button>
                : <ResolutionButton  key={"AllSizes"}
                                     tag={"Show All Papes"}
                                     minWidth={-1}
                                     minHeight={-1}
                                     maxWidth={-1}
                                     maxHeight={-1}
                                     filters={filters}
                                     setFilters={setFilters}
                                     setFiltersChanged={setFiltersChanged}
                                     orientation={orientation}
                                     setOrientation={setOrientation}
                                     setShowExactButton={setShowExactButton}
                                     buttoncssclass={"filterbutton"}/>
            }

            {orientation === "equal" ?
                <>
                    <button type="button" className={"filterbutton"} onClick={() => changeOrientation("width")}>
                    Show Desktop Papes</button>
                <button type="button" className={"filterbutton"} onClick={() => changeOrientation("height")}>
                    Show Mobile Papes</button></>
                :
                orientation === "width" ?
                    <><button type="button" className={"filterbutton"} onClick={() => changeOrientation("equal")}>
                        Show Desktop & Mobile</button>
                    <button type="button" className={"filterbutton"} onClick={() => changeOrientation("height")}>
                        Show Mobile Papes</button></>
                :
                    <><button type="button" className={"filterbutton"} onClick={() => changeOrientation("width")}>
                        Show Desktop Papes</button>
                    <button type="button" className={"filterbutton"} onClick={() => changeOrientation("equal")}>
                        Show Mobile & Desktop</button></>
            }

            {<LatestPapesButton key={"LatesPapes"}
                                        tag={"Show Latest Papes"}
                                        setFiltersChanged={setFiltersChanged}
                                        setOrientation={setOrientation}
                                        setShowExactButton={setShowExactButton}
                                        setLatestPapesClicked={setLatestPapesClicked} buttoncssclass={"filterbutton"}/>
            }

        </div>
    );
};
