import React from "react";

import "../css/ExpandButton.css"

export const ExpandButton = ({expandAll, setExpandAll}) => {

    return (
        <div className={"expand-button"} onMouseDown={() => setExpandAll(!expandAll)}>
            {expandAll ? <>Condense All</> : <>Expand All</>}
        </div>
    )

}
