import React from "react";
import { useNavigate } from 'react-router-dom';
import '../css/headerbar.css';

export const HeaderBar = () => {

    const navigate = useNavigate();

    return (
        <div>
            <span className={"headerbar"} onClick={() => navigate("/")} ><img src={"https://longpap.es/logo512.png"}/> longpap.es</span>
            <span className={"headerbar-tag"}>longpapes do what {(Math.random() < 0.1710) ? (Math.random() < 0.1710) ? 'sasith' : 'shawties' : 'shorties'} don't</span>
        </div>

    );

}
