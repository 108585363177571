import React, {useState} from "react";
import {makeStyles, Menu, MenuItem} from "@material-ui/core";
import {useNavigate} from "react-router-dom";

export const ResolutionButton = ({tag, minWidth, minHeight, maxWidth, maxHeight,
                                     filters, setFilters, setFiltersChanged, orientation,
                                     setOrientation, setShowExactButton, setFirstInput,
                                 buttoncssclass}) => {

    const navigate = useNavigate();

    function filterResolution() {

        if (setFirstInput) {
            setFirstInput(true);
        }

        setFilters({
            ...filters,
            minWidth: minWidth,
            minHeight: minHeight,
            maxWidth: maxWidth,
            maxHeight: maxHeight,
        });

        if(minWidth === -1 && minHeight === -1) {
            setShowExactButton(false);
            setOrientation("equal");
            navigate("/all");
        } else {
            setShowExactButton(true);
            let url_postfix = "";
            if (orientation === "width") {
                url_postfix = "/desktop";
            }
            else if (orientation === "height") {
                url_postfix = "/mobile";
            }
            navigate("/res/" + minWidth + "x" + minHeight + url_postfix);
        }

        setFiltersChanged(true);
    }

    return (
        <button type="button" className={`menubutton ${buttoncssclass}`} onClick={() => filterResolution()}>{tag}</button>
    )
};



export const LatestPapesButton = ({tag, setFiltersChanged, setOrientation, setShowExactButton,
                                      setFirstInput, setLatestPapesClicked, buttoncssclass}) => {

    const navigate = useNavigate();

    function showLatestPapes() {

        if (setFirstInput) {
            setFirstInput(true);
        }

        setOrientation("equal");
        setShowExactButton(false);
        setLatestPapesClicked(true);
        setFiltersChanged(true);
        navigate("/latest");
    }

    return (
        <button type="button" className={buttoncssclass} onClick={() => showLatestPapes()}>{tag}</button>
    )
};




export const ChooseResolutionButton = ({resolutions, tag, filters, setFilters, setFiltersChanged, orientation,
                                           setOrientation, setShowExactButton, setFirstInput, buttoncssclass}) => {

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function filterResolution(minWidth, minHeight) {

        handleClose();
        if (setFirstInput) {
            setFirstInput(true);
        }

        setFilters({
            ...filters,
            minWidth: minWidth,
            minHeight: minHeight,
            maxWidth: -1,
            maxHeight: -1,
        });

        if(minWidth === -1 && minHeight === -1) {
            setShowExactButton(false);
            navigate("/all");
        } else {
            setShowExactButton(true);
            let url_postfix = "";
            if (orientation === "width") {
                url_postfix = "/desktop";
            }
            else if (orientation === "height") {
                url_postfix = "/mobile";
            }
            navigate("/res/" + minWidth + "x" + minHeight + url_postfix);
        }

        setFiltersChanged(true);
    }


    const [enterWidth, setEnterWidth] = useState("");
    const [enterHeight, setEnterHeight] = useState("");
    const handleSubmit = (evt) => {
        evt.preventDefault();
        if (enterWidth === "") setEnterWidth(-1);
        if (enterHeight === "") setEnterHeight(-1);
        filterResolution(enterWidth, enterHeight);
    }


    return (<>
        <button type="button" className={`menubutton ${buttoncssclass}`} style={{margin: 0}} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} >
            {tag}
        </button>
        <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
        >

            {!setFirstInput && <MenuItem key={"1"} onClick={() => filterResolution(Math.round(window.screen.width * (window.devicePixelRatio || 1)), Math.round(window.screen.height * (window.devicePixelRatio || 1)))}>
                • Detect
            </MenuItem>}

            {resolutions.map(resolution => <MenuItem key={resolution.name} onClick={() => filterResolution(resolution.width,resolution.height)}>
                ◦ {resolution.name}
            </MenuItem>)}

            {!setFirstInput && <MenuItem onClick={() => filterResolution(-1, -1)}>
                • All Sizes
            </MenuItem>}

            {<MenuItem>
                <form onSubmit={handleSubmit}>
                    <label>
                        Or Enter Resolution:<br/>
                        <input
                            type="text" pattern="\d*"
                            value={enterWidth}
                            onChange={e => setEnterWidth(e.target.value)}
                            style={{maxWidth: 35, marginBottom: 5}}
                        />x
                        <input
                            type="text" pattern="\d*"
                            value={enterHeight}
                            onChange={e => setEnterHeight(e.target.value)}
                            style={{maxWidth: 35, marginBottom: 5}}
                        />
                    </label> <br/>
                    {(enterWidth !== "" || enterHeight !== "") && <input type="submit" value="Show Papes" /> }
                </form>
            </MenuItem>}
        </Menu>
    </>)

};
