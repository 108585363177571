import React from "react";
import PropTypes from "prop-types";
import '../css/youtube.css';

export const DizasterEasteregg = ({}) => {
    const YoutubeEmbed = ({ embedId }) => (
        <div className="video-responsive">
            <iframe
                width="853"
                height="480"
                src={`https://www.youtube.com/embed/${embedId}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Longpapes looongpapes"
            />
        </div>
    );

    YoutubeEmbed.propTypes = {
        embedId: PropTypes.string.isRequired
    };

    return (
        <><br/>
            <YoutubeEmbed embedId="AP7utU8Efow?autoplay=1" />
        </>
    )
}
