import React, {useRef, useState} from "react";
import ImageGallery from 'react-image-gallery';

export const LatestGallery = ({listFetched, galleryPapes}) => {

    const galleryElement = useRef(null);
    const images = [];

    let index = 1;

    if (listFetched) {
        for (var i = 0; i < 2; i++) {
            images.push({original: `https://longpap.es/${galleryPapes[i].src}`});
        }
    }

    const loadMore = () => {
        if (images.length < galleryPapes.length) {
            if (index === images.length) {
                images.push({original: `https://longpap.es/${galleryPapes[images.length].src}`})
            }
            index++;
        }
    }

    const onImageClick = () => {
        // `current` points to the mounted text input element
        openInNewTab(`https://longpap.es/${galleryPapes[galleryElement.current.getCurrentIndex()].src}`)
    };

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return(
        <div style={{cursor: 'pointer', maxHeight: 600}}>
            <ImageGallery

                ref={galleryElement}

                items={images}
                showThumbnails={false}
                showNav={false}
                showFullscreenButton={false}
                showPlayButton={false}
                autoPlay={true}
                slideDuration={600}
                slideInterval={7000}

                onClick={() => onImageClick()}
                onImageLoad={() => loadMore()}
            />
        </div>
    );

}
